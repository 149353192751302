import React, { Component } from 'react';
import classNames from 'classnames';
import { getOpenTypeClass } from './';

export const EmptyScheme = () => <div>Схема временно отсутствует</div>;

export default class Window extends Component {
	render() {
		return(
			<Frame common={this.props.common} position={this.props.position} leafOpenTypes={this.props.leafOpenTypes} />
		);
	}
}

const Frame = ({common, position, leafOpenTypes}) => {
	let SectionsList;
    
	if (common) {
		SectionsList = <SectionGroup common={common}>
			{leafOpenTypes.map((item, index) => 
				<Section key={item} id={index + 1} leafOpenTypes={leafOpenTypes}/>
			)}
		</SectionGroup>;
	} else {
		SectionsList = <>
			<SectionGroup up>
				{position.up.map(item => <Section key={item} id={item} leafOpenTypes={leafOpenTypes} />)}
			</SectionGroup>			
			<SectionGroup down>
				{position.down.map(item => <Section key={item} id={item} leafOpenTypes={leafOpenTypes} />)}
			</SectionGroup>			
		</>;
	};

	return (
		<div className="frame-wrap">
			<div className="frame window-frame">
				{SectionsList}
			</div>
		</div>	
	);
};

const SectionGroup = ({common, up, down, children}) => {
	return (
		<div
			className={classNames('flex section-group', {
				'section-group--common': common,
				'section-group--up': up,
				'section-group--down': down,
			})}
		>
			{children}
		</div>	
	);
};

const Section = ({id, leafOpenTypes}) => {
	const cssClass = getOpenTypeClass(id, leafOpenTypes);
	return <div className={`section ${cssClass}`}>{id}</div>;	
};

Section.defaultProps = {
	leafOpenTypes: [],
};