import React, { Component } from 'react';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import Routes from './Routes';
import NotificationsContainer from './app/Notifications/NotificationsContainer';

const store = configureStore({});

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<Routes />
				<NotificationsContainer />
			</Provider>
		);
	}
}

export default App;