import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from './Header';
import NavBar from './NavBar';

class Layout extends PureComponent {

  render() {
		const {children} = this.props;

    	return (
			<Container>
				<Header />
				<Row className="content-wrapper">
					<Col sm={3}>
						<NavBar />
					</Col>
					<Col sm={9}>
						<div className="content">
			 				{children}
				 		</div>
					</Col>
				</Row>
			</Container>
    );
  }
}

export default Layout;
