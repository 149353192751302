import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { Loader, Pagination } from '../../../../ui';
import OrdersListItem from './OrdersListItem';
import MapModal from '../Map/MapModal';
import DetailedOrderModal from '../DetailedOrder/DetailedOrderModal';
import { isLaminationsFetching } from '../../../Terms/selectors';

class OrdersList extends Component {

	static propTypes = {
		status: PropTypes.string,
	};

	componentDidMount() {
		this.props.fetchProfilesList();
		this.props.fetchLaminationsList();
		this.props.fetchOrdersList({status: this.props.status});
	}

	componentWillReceiveProps(nextProps) {
		const {searchParameters, fetchOrdersList} = this.props;
        if (nextProps.searchParameters.filter !== searchParameters.filter) {
			this.throttleRequest(nextProps.searchParameters);
		} else if (
			nextProps.searchParameters.currentPage !== searchParameters.currentPage
			|| nextProps.searchParameters.from !== searchParameters.from
			|| nextProps.searchParameters.to !== searchParameters.to
			|| nextProps.searchParameters.sortDirection !== searchParameters.sortDirection
		) {
            fetchOrdersList(nextProps.searchParameters);
		}
	}

	throttleRequest = debounce(300, this.props.fetchOrdersList);

	handleSelectCurrentPage = (page) => this.props.setCurrentPage(page);

	closeMapModal = () => this.props.history.push('/orders');
	
	closeOrderModal = () => {
		const { history, match: {
			params: {
				visibleOrderId
			}
		}} = this.props;
		const deletedPath = `/${visibleOrderId}`;
		const newUrl = history.location.pathname.replace(deletedPath, '');
		history.push(newUrl);
	};

	render() {
		const {
			isFetching,
			isProfilesFetching,
			isLaminationsFetching,
			orders,
			pagination: {
				totalPages,
				currentPage,
				totalItems,
				elementsOnPage
			},
		} = this.props;

		const isContentFetching = isFetching && isProfilesFetching && isLaminationsFetching; 
		const shouldMapModalOpen = this.props.match.path.includes('/orders/map');
		const shouldOrderModalOpen = Boolean(this.props.match.params.visibleOrderId);

		return (
			isContentFetching ?
				<Loader size="md"/>
			:
			<div className="flex flex-col flex--ai_center appearance">
				<div className="list-container orders-list">
					{orders.map(order =>
						<OrdersListItem
							order={order}
							key={order.id}
						/>
					)}
				</div>
				<Pagination
					pages={totalPages}
					page={currentPage}
					totalItemsLength={totalItems}
					elementsOnPage={elementsOnPage}
					onSelect={this.props.setCurrentPage}
				/>
				<DetailedOrderModal isOpen={shouldOrderModalOpen} toggle={this.closeOrderModal} />
				<MapModal isOpen={shouldMapModalOpen} toggle={this.closeMapModal} />
			</div>
		);
	}
}

export default OrdersList;