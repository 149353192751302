export const ORDER_CHANGE_STATUS = 'ORDER_CHANGE_STATUS';

export const ORDER_START_CHANGE_STATUS = 'ORDER_START_CHANGE_STATUS';
export const ORDER_STOP_CHANGE_STATUS = 'ORDER_STOP_CHANGE_STATUS';

export const SET = 'SET';

export const ORDERS_LIST_START = 'ORDERS_LIST_START';
export const ORDERS_LIST_STOP = 'ORDERS_LIST_STOP';
export const ORDERS_LIST_SUCCESS = 'ORDERS_LIST_SUCCESS';
export const ORDERS_LIST_FAIL = 'ORDERS_LIST_FAIL';
export const ORDERS_LIST_SELECT_ALL = 'ORDERS_LIST_SELECT_ALL';
export const ORDERS_LIST_UNSELECT_ALL = 'ORDERS_LIST_UNSELECT_ALL';
export const ORDERS_LIST_SEARCH = 'ORDERS_LIST_SEARCH';
export const ORDERS_LIST_SET_PAGE = 'ORDERS_LIST_SET_PAGE';

export const START_DOWNLOAD_XLSX = 'START_DOWNLOAD_XLSX';
export const STOP_DOWNLOAD_XLSX = 'STOP_DOWNLOAD_XLSX';
export const START_DOWNLOAD_CSV = 'START_DOWNLOAD_CSV';
export const STOP_DOWNLOAD_CSV = 'STOP_DOWNLOAD_CSV';

export const DETAILED_ORDER_START = 'DETAILED_ORDER_START';
export const DETAILED_ORDER_STOP = 'DETAILED_ORDER_STOP';
export const DETAILED_ORDER_SUCCESS = 'DETAILED_ORDER_SUCCESS';
export const ORDER_ITEM_SELECT = 'ORDER_ITEM_SELECT';
export const ORDER_ITEM_UNSELECT = 'ORDER_ITEM_UNSELECT';