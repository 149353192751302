import React from 'react';
import { Row, Col } from 'reactstrap';

const Field = ({title, titleSm, value, valueSm}) => (
	<Row className="flex--ai_baseline">
		<Col sm={titleSm} className="field-title">
			{title}
		</Col>
		<Col sm={valueSm} className="field-value">
			{value}
		</Col>
	</Row>
);

Field.defaultProps = {
	titleSm: 4,
	valueSm: 6,
};

export default Field;
