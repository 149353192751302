import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import { fetchOrdersList, setCurrentPage } from '../../actions';
import { fetchProfilesList, fetchLaminationsList } from '../../../Terms/actions';
import { isOrdersListFetching, getOrdersList, getSearchParameters, getPagingParameters } from '../../selectors';
import { isProfilesFetching } from '../../../Terms/selectors';
import OrdersList from './';

const mapStateToProps = (state) => ({
	isFetching: isOrdersListFetching(state),
	isProfilesFetching: isProfilesFetching(state),
	orders: getOrdersList(state),
	searchParameters: getSearchParameters(state),
	pagination: getPagingParameters(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	fetchProfilesList,
	fetchLaminationsList,
	fetchOrdersList,
	setCurrentPage,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersList));
