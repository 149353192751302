import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';

export default class FileDropzone extends PureComponent {

    constructor() {
        super()
        this.state = {
          files: []
        }
    }

    static defaultProps = {
        className: '',
        multiple: false,
    }

    onDrop(files) {
		this.setState({files});
    }

    onCancel() {
        this.setState({
        	files: []
    	});
	}

	 render() {
		const {multiple, className, title, icon} = this.props;
		const files = this.state.files.map(file => (
			<div key={file.name}>
				{file.name} - {file.size} bytes
			</div>
		));

        return (
        	<section className={className}>
				<Dropzone
					onDrop={this.onDrop.bind(this)}
					onFileDialogCancel={this.onCancel.bind(this)}
					multiple={multiple}
				>
					{({getRootProps,
						getInputProps,
						isDragActive,
						isDragAccept
					}) => (
						<div
							{...getRootProps()}
							className={classNames('drag-zone', {
								'drag-zone--active': isDragActive,
								'drag-zone--accept': isDragAccept,
							})}
						>
            			<input {...getInputProps()} />
						<div className="drag-zone__icon-circle">
							{icon}
						</div>
						<div className="drag-zone__title">
							{files.length > 0 ? files : title}
						</div>
						<div className="drag-zone__subtitle">
							{files.length > 0 ? "заменить" : "или загрузите его"}
						</div>
                 </div>
            	)}
			</Dropzone>
          </section>
		);
	}
}
