export const DATE_FORMAT = 'DD MMMM YYYY';
export const SHORT_DATE_FORMAT = 'D/MM/YY';
export const ITEMS_PER_PAGE = 8;
export const ROSTOV_COORD_CENTER = {
	lat: 47.2313500,
	lng: 39.7232800
};
export const STATUSES = {
	CREATED: 'CREATED',
	ARCHIVED: 'ARCHIVED'
};

export const messages = {
	'errors': {
		'Unknown': 'Неизвестная ошибка',
		'Bad credentials': 'Неверные данные',
		'Ошибка сервера': 'Ошибка сервера',
		'Authorization required': 'Требуется авторизация',
		'Orders is not found': 'Заявка не найдена',
	}
};

export const clientsMessages = {
	'errors': {
		'Unknown': 'Неизвестная ошибка',
		'Empty': 'Поле обязательно для заполнения',
	}
};

export const windowsTerms = {
	'configuration': {
		'WINDOW': 'WINDOW',
		'DOOR': 'DOOR',
		'WINDOW_DOOR': 'WINDOW_DOOR',
		'DOOR_WINDOW': 'DOOR_WINDOW',
		'WINDOW_DOOR_WINDOW': 'WINDOW_DOOR_WINDOW',
	},
	'construction': {
		'WINDOW_1': 'Окно одностворчатое',
		'WINDOW_2': 'Окно двухстворчатое',
		'WINDOW_3': 'Окно трехстворчатое',
		'WINDOW_4': 'Окно трехстворчатое с фрамугой',
		'WINDOW_5': 'Окно двухстворчатое с фрамугой',
		'WINDOW_6': 'Окно одностворчатое с фрамугой',
		'WINDOW_7': 'Окно двустворчатое с двумя фрамугами',
		'DOOR_1': 'Дверь одностворчатая с глухой нижней частью',
		'DOOR_2': 'Дверь двустворчатая с глухой нижней частью',
		'DOOR_3': 'Дверь цельная одностворчатая',
		'DOOR_4': 'Дверь цельная двустворчатая',

	},
	'leafOpenType': {
		null: 'Глухой',
		'LEFT': 'Влево',
		'LEFT_UP': 'Влево вверх',
		'RIGHT': 'Вправо',
		'RIGHT_UP': 'Вправо вверх',
		'UP': 'Вверх',
	},
	'profile': {
		'SOFTLINE_70': 'Softline',
		'SOFTLINE_82 ': 'Softline 82',
		'ARTLINE': 'Artline',
		'PROLINE': 'Proline',
		'EUROLINE': 'Euroline',
		'WHS_60': 'WHS 60',
		'WHS_72': 'WHS 72',
	},
	'lamination': {
		'TEXTURE_1': 'Белый',
		'TEXTURE_2': 'Серый сланец 701505',
		'TEXTURE_3': 'Светлая пихта 3152009',
		'TEXTURE_4': 'Махагон 2065021',
		'TEXTURE_5': 'Орех 2178007',
		'TEXTURE_6': 'Светлый дуб 3118076',
		'TEXTURE_7': 'Горная сосна 3069041',
	},
};

export const ACTION_GROUP = 'Действия с группой';

export const actionsWithGroup = [
	{	type: 'SELECT_ACTION',
		title: ACTION_GROUP
	},
	{
		type: 'PRINT',
		title: 'Распечатать'
	},
	{
		type: 'DELETE',
		title: 'Удалить'
	}
];

export const sortingOptions = [
	{
		type: 'ASC',
		title: 'Сначала старые'
	},
	{
		type: 'DESC',
		title: 'Сначала новые'
	}
];

export const options = [
	{
		type: 'FILTER_1',
		title: 'Значение 1',
	},
	{
		type: 'FILTER_2',
		title: 'Значение 2',
	},
	{
		type: 'FILTER_3',
		title: 'Значение 3',
	},
	{
		type: 'FILTER_4',
		title: 'Значение 4',
	}
];