import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {createGlobalStyle} from "styled-components";
import {Container, Col} from 'reactstrap';
import {Input, Button, Loader} from '../../ui';
import {handleSetLoginForm, fetchUserLogin, showValidationErrors} from './actions';
import {getLoginForm, isUserLoginFetching, isFormValid, needShowValidationErrors} from "./selectors";
import {clientsMessages} from '../../utils/constants';
import logoCenter from '../../assets/img/logocenter@3x.png';

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #f5f5f5 !important;
    }
`;

class LoginForm extends Component {

    handleEmailChange = value => {
        this.props.handleSetLoginForm({email: value});
    };

    handlePasswordChange = value => {
        this.props.handleSetLoginForm({password: value});
    };

    handlerSubmit = (event) => {
        event.preventDefault();

        const {email, password} = this.props.form;
        const {isFormValid, fetchUserLogin, showValidationErrors} = this.props;
        
        if(isFormValid) {
            fetchUserLogin(email, password)
            .then( () => {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                    this.props.history.push('/orders');
                }                
            });
        } else {
            showValidationErrors();
        }
    }
    
	render() {
        const {isUserLoginFetching, needShowValidationErrors, form: { email, password }} = this.props;
        let error;
        if (!email && needShowValidationErrors) {
            error = clientsMessages.errors['Empty'];
        }

		return (
            <Container className="login-form-container appearance">
              <GlobalStyle />

                <form className="login-form-block flex flex-col flex--ai_center">
                    <div className="logo-wrap">
                        <img src={logoCenter} alt="Конфигуратор VEKA" />
                    </div>
                    <Col sm={9}>
                        <Input
                            placeholder="Логин"
                            size="lg"
                            name="login"
                            value={email}
                            onChange={this.handleEmailChange}
                            needShowErrors={!email && needShowValidationErrors}
                            errorMessage={error}
                        />
                    </Col>

                    <Col sm={9}>
                        <Input
                            placeholder="Пароль"
                            size="lg"
                            type="password"
                            name="password"
                            autocomplete="on"
                            value={password}
                            onChange={this.handlePasswordChange}
                            needShowErrors={!password && needShowValidationErrors}
                            errorMessage={clientsMessages.errors['Empty']}
                        />
                    </Col>

                    <Col sm={9}>
                        <Button type="submit" kind="primary" size="lg" isDisabled={isUserLoginFetching} onClick={this.handlerSubmit}>
                            {isUserLoginFetching ? <Loader white/> : 'Войти'}
                        </Button>
                    </Col>
                </form>
        </Container>
		);
	}
}

const mapStateToProps = (state) => ({
    form: getLoginForm(state),
    isUserLoginFetching: isUserLoginFetching(state),
    isFormValid: isFormValid(state),
    needShowValidationErrors: needShowValidationErrors(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    handleSetLoginForm,
    fetchUserLogin,
    showValidationErrors
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
