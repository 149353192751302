import { createAction } from 'redux-actions';
import * as actions from './actionTypes';
import { apiRequest, apiEndpoint, needRefreshToken, fetchRefreshToken } from '../../api';
import { downloadFileFromResponse } from '../../utils/helpers';
import { notifyError } from '../Notifications';
import { ITEMS_PER_PAGE } from '../../utils/constants';
import { getSearchParameters } from './selectors';

export const selectOrder = createAction (
	actions.ORDER_ITEM_SELECT,
	selectedOrderId => ({selectedOrderId}),
);

export const unselectOrder = createAction (
	actions.ORDER_ITEM_UNSELECT,
	unselectedOrderId => ({unselectedOrderId}),
);

export const selectAllOrders = createAction (
	actions.ORDERS_LIST_SELECT_ALL,
	selectedAllOrders => ({selectedAllOrders}),
);
export const unselectAllOrders = createAction (
	actions.ORDERS_LIST_UNSELECT_ALL,
	unselectedAllOrders => ({unselectedAllOrders}),
);

export const handleSearch = createAction (
	actions.ORDERS_LIST_SEARCH,
	searchParameters => ({searchParameters})
);

export const setCurrentPage = createAction (
	actions.ORDERS_LIST_SET_PAGE,
	currentPage => ({currentPage}),
);

export const startOrdersList = createAction (actions.ORDERS_LIST_START);
export const stopOrdersList = createAction (actions.ORDERS_LIST_STOP);

export const setOrdersListSuccess = createAction(
	actions.ORDERS_LIST_SUCCESS,
	ordersData => ({ordersData})
);

export const fetchOrdersList = (searchParameters) => (dispatch) => {
	dispatch(startOrdersList());

	let queryParameters = {
		limit: ITEMS_PER_PAGE,
	};

	if (searchParameters) {
		queryParameters.page = searchParameters.currentPage - 1 || undefined;
		queryParameters.filter = searchParameters.filter || undefined;
		queryParameters.statuses = searchParameters.status || undefined;
		queryParameters.fromDate = searchParameters.from || undefined;
		queryParameters.toDate = searchParameters.to || undefined;
		queryParameters.sortDirection = searchParameters.sortDirection || undefined;
	}

	return apiRequest(dispatch, {
		url: '/admin/orders',
		queryParameters: queryParameters
	}).then(responseData => {
		if (responseData.result === 'SUCCESS') {
			dispatch(setOrdersListSuccess(responseData));
			dispatch(stopOrdersList());
		} else if (responseData.result && responseData.result !== 'SUCCESS') {
			dispatch(notifyError(responseData.message || 'Неизвестная ошибка'));
		} else {
			dispatch(notifyError('Ошибка сервера'));
		}
	});
};

export const startDownloadXLSX = createAction (actions.START_DOWNLOAD_XLSX);
export const stopDownloadXLSX = createAction (actions.STOP_DOWNLOAD_XLSX);

export const fetchDownloadXLSX = () => (dispatch, getState) => {
	dispatch(startDownloadXLSX());
	const store = getState();
	const searchParameters = getSearchParameters(store);
	let queryParameters = {
		format: 'XLSX'
	};

	queryParameters.filter = searchParameters.filter || undefined;
	queryParameters.statuses = searchParameters.status || undefined;
	queryParameters.fromDate = searchParameters.from || undefined;
	queryParameters.toDate = searchParameters.to || undefined;
	queryParameters.sortDirection = searchParameters.sortDirection || undefined;


	return apiRequest(dispatch, {
		type: 'blob',
		url: '/admin/orders/export',
		queryParameters: queryParameters,
	}).then(responseData => {
		if (responseData) {
			if (responseData.constructor.name === 'Blob') {
				downloadFileFromResponse(responseData);
				dispatch(stopDownloadXLSX());
			} else {
				dispatch(notifyError('Неизвестная ошибка'));
				dispatch(stopDownloadXLSX());
			}
		} else {
			dispatch(notifyError('Неизвестная ошибка'));
		}
	});
};

export const startDownloadCSV = createAction (actions.START_DOWNLOAD_CSV);
export const stopDownloadCSV = createAction (actions.STOP_DOWNLOAD_CSV);

export const fetchDownloadCSV = () => (dispatch, getState) => {
	dispatch(startDownloadCSV());
	const store = getState();
	const searchParameters = getSearchParameters(store);
	let queryParameters = {
		format: 'CSV'
	};
	
	queryParameters.filter = searchParameters.filter || undefined;
	queryParameters.statuses = searchParameters.status || undefined;
	queryParameters.fromDate = searchParameters.from || undefined;
	queryParameters.toDate = searchParameters.to || undefined;
	queryParameters.sortDirection = searchParameters.sortDirection || undefined;

	return apiRequest(dispatch, {
		accept: 'text/csv',
		type: 'blob',
		url: '/admin/orders/export',
		queryParameters: queryParameters,
	}).then(responseData => {
		if (responseData) {
			if (responseData.constructor.name === 'Blob') {
				downloadFileFromResponse(responseData);
				dispatch(stopDownloadCSV());
			} else {
				dispatch(notifyError('Неизвестная ошибка'));
				dispatch(stopDownloadCSV());
			}
		} else {
			dispatch(notifyError('Неизвестная ошибка'));
		}
	});
};

export const startDetailedOrder = createAction (actions.DETAILED_ORDER_START);
export const stopDetailedOrder = createAction (actions.DETAILED_ORDER_STOP);

export const setDetailedOrderSuccess = createAction(
	actions.DETAILED_ORDER_SUCCESS,
	detailedOrder => ({detailedOrder})
);

export const fetchDetailedOrder = (orderId) => async(dispatch) => {

	dispatch(startDetailedOrder());

	if (needRefreshToken()) {
		await fetchRefreshToken();
	}
	
	return fetch(`${apiEndpoint}/admin/orders/getByVisibleId?visibleId=${orderId}`, {    
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
		},
	})
		.then(response => {
			if (response.status >= 400) {
				dispatch(notifyError('Ошибка сервера'));
				return response;
			}

			let contentType = response.headers.get('content-type');
			if (contentType && contentType.includes('application/json')) {
				return response.json();
			} else {
				return response;
			}
		})
		.then(dataJSON => {
			if (dataJSON.result === 'SUCCESS') {
				dispatch(setDetailedOrderSuccess(dataJSON.object));
				dispatch(stopDetailedOrder());
			} else if (dataJSON.result && dataJSON.result !== 'SUCCESS') {
				dispatch(notifyError(dataJSON.message || 'Неизвестная ошибка'));
			} else {
				dispatch(notifyError('Ошибка сервера'));
			}
		});
};
