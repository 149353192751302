import React from 'react';
import { show } from 'react-notification-system-redux';
import ErrorIcon from '../../ui/icons/ErrorIcon';
import Cross from '../../ui/icons/Cross';
import { messages } from '../../utils/constants';

const notify = (level, msg, icon, uid) => show({
  uid,
  position: 'tc',
  autoDismiss: 5,
  dismissible: 'both',
  children: (
    <div className="notification-content container-fix">
    	{icon ? <div className="notification-icon">{icon}</div> : null}
    	<div className="notification-message">{msg}</div>
    	<div className="notification-dismiss"><Cross size={12} /></div>
    </div>),
}, level);

export const notifyInfo = (msg, uid = 'info') => notify('info', msg, '', uid);
export const notifySuccess = (msg, uid = 'success') => notify('success', msg, '', uid);
export const notifyWarning = (msg, uid = 'warning') => notify('warning', msg, '', uid);
export const notifyError = (msg, uid = 'error') => {
  let newMsg = messages.errors[msg] || messages.errors["Unknown"];
  return notify('error', newMsg, <ErrorIcon size={16} />, uid);
}
