import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleSearch } from '../Orders/actions';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/ru';
import { Input, Button } from '../../ui';
import { getDateFormat } from '../../utils/helpers';
import { SHORT_DATE_FORMAT } from '../../utils/constants';
import 'react-day-picker/lib/style.css';

class DatePicker extends Component {

    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.resetDates = this.resetDates.bind(this);
    }

    state = {
        from: undefined,
        to: undefined,
        isOpened: false,
        inputValue: 'Выберите даты'
    };
    
    getInitialState() {
        return {
          from: undefined,
          to: undefined,
          isOpened: false,
          inputValue: 'Выберите даты'
        };
    }

    static defaultProps = {
        numberOfMonths: 1,
    }

    componentDidMount() {
        window.document.addEventListener('mousedown', this.handleClickOutside);
    }
    
      componentWillUnmount() {
        window.document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = ({ target }) => {
        try {
          if (!this.wrapperNode || !this.wrapperNode.contains(target)) {
            this.setState({ isOpened: false });
          }
        } catch (err) {
          //
        }
    }

    handleFocus = () =>  this.setState({ isOpened: true });

    handleDayClick(day) {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
        this.props.handleSearch({
            from: getDateFormat(range.from, 'YYYY-MM-DD'),
            to: getDateFormat(range.to, 'YYYY-MM-DD'),
        });        
        if (range.from && range.to) {
            if(range.from.toISOString() === range.to.toISOString()) {
                this.setState({ inputValue: `${getDateFormat(range.from, SHORT_DATE_FORMAT)}` });
            } else {
                this.setState({ inputValue: `${getDateFormat(range.from, SHORT_DATE_FORMAT)} - ${getDateFormat(range.to, SHORT_DATE_FORMAT)}` }); 
            }
        } else if (range.from && !range.to) {
            this.setState({ inputValue: `${getDateFormat(range.from, SHORT_DATE_FORMAT)}` });
        }
        else {
           this.resetDates();
        }
    }

    resetDates = () => {
        this.setState(this.getInitialState());
        this.props.handleSearch(this.getInitialState());
    }

	render() {
        const { isOpened, from, to, inputValue } = this.state;
        const modifiers = { start: from, end: to };
        const disabledDays = [{ after: new Date() }];
        
        return (
            <div className="date-input-container" ref={(node) => { this.wrapperNode = node; }}>
                <Input
                    onFocus={this.handleFocus}
                    size="sm"
                    className='date-picker-input'
                    value={inputValue}
                />
                {isOpened ?
                <div className="date-picker-container">
                    <DayPicker
                        locale='ru'
                        localeUtils={MomentLocaleUtils} 
                        numberOfMonths={this.props.numberOfMonths}
                        selectedDays={[from, { from, to }]}
                        onDayClick={this.handleDayClick}
                        modifiers={modifiers}
                        disabledDays={disabledDays}
                    />
                    <Button className='reset-btn' opacity={100} kind="tertiary" size="sm" onClick={this.resetDates}>
                        Сбросить фильтрацию по дате
                    </Button>
                </div>
                : null}
            </div>
		);
	}
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    handleSearch
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
