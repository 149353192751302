import {handleActions} from 'redux-actions';
import  * as types from './actionTypes';

export const initialState = {
	isOrdersListFetching: false,
	ordersList: [],
	isDetailedOrderFetching: false,
	detailedOrder: {},
	orderCoordinates: {},
	configurations: {},
	selectedOrders: [],
	searchParameters: {
		currentPage: 1,
		from: undefined,
		to: undefined,
	},
	totalItems: 0,
	isXLSXDocumentFetching: false,
	isCSVDocumentFetching: false,
	isNewStatusFetching: false,
};

export default handleActions({
	[types.ORDERS_LIST_START]: (state) => ({
		...state,
		isOrdersListFetching: true,
	}),
	[types.ORDERS_LIST_STOP]: (state) => ({
		...state,
		isOrdersListFetching: false,
	}),
	[types.ORDERS_LIST_SUCCESS]: (state, action) => ({
		...state,
		ordersList: action.payload.ordersData.list,
		totalItems: action.payload.ordersData.total,
	}),
	[types.DETAILED_ORDER_START]: (state) => ({
		...state,
		isDetailedOrderFetching: true,
	}),
	[types.DETAILED_ORDER_STOP]: (state) => ({
		...state,
		isDetailedOrderFetching: false,
	}),	
	[types.DETAILED_ORDER_SUCCESS]: (state) => ({
		...state,
		isDetailedOrderFetching: false,
	}),
	[types.DETAILED_ORDER_SUCCESS]: (state, action) => ({
		...state,
		detailedOrder: action.payload.detailedOrder,
		orderCoordinates: action.payload.detailedOrder.coord,
		configurations: action.payload.detailedOrder.configurations,
	}),
	[types.ORDER_ITEM_SELECT]: (state, action) => ({
		...state,
		selectedOrders: [...state.selectedOrders, action.payload.selectedOrderId]
	}),
	[types.ORDER_ITEM_UNSELECT]: (state, action) => ({
		...state,
		selectedOrders: state.selectedOrders.filter(id => id !== action.payload.unselectedOrderId)
	}),
	[types.ORDERS_LIST_SELECT_ALL]: (state) => ({
		...state,
		selectedOrders: state.ordersList.map(item => item.id)
	}),
	[types.ORDERS_LIST_UNSELECT_ALL]: (state) => ({
		...state,
		selectedOrders: []
	}),
	[types.ORDERS_LIST_SEARCH]: (state, action) => ({
		...state,
		searchParameters: {
			...state.searchParameters,
			...action.payload.searchParameters,
			currentPage: initialState.searchParameters.currentPage
		},
	}),
	[types.ORDERS_LIST_SET_PAGE]: (state, action) => ({
		...state,
		searchParameters: {
			...state.searchParameters,
			currentPage: action.payload.currentPage,
		},
		selectedOrders: []
	}),
	[types.START_DOWNLOAD_XLSX]: (state) => ({
		...state,
		isXLSXDocumentFetching: true,
	}),
	[types.STOP_DOWNLOAD_XLSX]: (state) => ({
		...state,
		isXLSXDocumentFetching: false,
	}),
	[types.START_DOWNLOAD_CSV]: (state) => ({
		...state,
		isCSVDocumentFetching: true,
	}),
	[types.STOP_DOWNLOAD_CSV]: (state) => ({
		...state,
		isCSVDocumentFetching: false,
	}),
	[types.ORDER_START_CHANGE_STATUS]: (state) => ({
		...state,
		isNewStatusFetching: true,
	}),
	[types.ORDER_STOP_CHANGE_STATUS]: (state, action) => ({
		...state,
		isNewStatusFetching: false,
		// ordersList: action.payload.ordersData.list,
	}),
}, initialState);
