import React, { Component } from 'react';
import {ModalBody} from 'reactstrap';

export default class PopupBody extends Component {

	render() {
		const {children} = this.props;

		return (
			<ModalBody>
				{children}
			</ModalBody>
		);
	}
}