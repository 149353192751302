import React, { PureComponent } from 'react';
import {  Router, Route, Switch, Redirect } from 'react-router-dom';
import AccessRoute from './AccessRoute';
import createHistory from 'history/createBrowserHistory';
import Layout from './Layout/Layout';
import LoginForm from './app/Login';
import NewOrdersPage from './app/Orders/components/OrdersPages/NewOrdersPage';
import ArchivedOrdersPage from './app/Orders/components/OrdersPages/ArchivedOrdersPage';
import InDeveloping from './app/EmptyLists/InDeveloping';

export const appHistory = createHistory();

export default class Routes extends PureComponent {

	render() {
		return (
			<Router history={appHistory}>
				<Switch>
					<Route exact path="/login" component={LoginForm} />
					<Layout >
						<Switch>
							<AccessRoute exact path="/orders" component={NewOrdersPage} />
							<AccessRoute exact path="/archive" component={ArchivedOrdersPage} />
							<AccessRoute exact path="/orders/map" component={NewOrdersPage} />
							<AccessRoute exact path="/orders/:visibleOrderId" component={NewOrdersPage}/>
							<AccessRoute exact path="/archive/:visibleOrderId" component={ArchivedOrdersPage}/>
							<AccessRoute exact path="/3Dmodels" component={InDeveloping} />
							<AccessRoute exact path="/clients" component={InDeveloping} />
							<Redirect from="/" to="/orders" />
						</Switch>
					</Layout>
				</Switch>
			</Router>
		);
	}
}
