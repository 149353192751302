import React, { Component } from 'react';
import { Modal } from 'reactstrap';

export default class PopupContainer extends Component {

    static defaultProps = {
        className: '',
    }

    handleToggle = () => this.props.toggle();

    render() {
        const {isOpen, children, toggle, className} = this.props;

		return (
            <Modal
                isOpen={isOpen}
                toggle={toggle ? this.handleToggle : null}
                className={`${className} ui-modal-container`}
            >

                {children}
            </Modal>
		);
	}
}



