import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Paginator from 'react-pagify';
import pagifyBootstrapPreset from 'react-pagify-preset-bootstrap';
import segmentize from 'segmentize';
import classNames from 'classnames';
import ArrowRight from '../icons/ArrowRight';

export default class Pagination extends PureComponent {
	
	static propTypes = {
		page: PropTypes.number.isRequired,
		pages: PropTypes.number.isRequired,
		totalItemsLength: PropTypes.number.isRequired,
		elementsOnPage: PropTypes.number,
		onSelect: PropTypes.func.isRequired,
	}

	static defaultProps = {
		page: 1,
	};

	handleSelect = (newPage, event) => {
		event.preventDefault();
		this.props.onSelect(newPage);
	};

	render() {
		const {pages, page} = this.props;
		return pages > 1 ? (
			<div className="pagination-container">
				<Paginator.Context
					{...pagifyBootstrapPreset}
					segments={segmentize({
						pages,
						page,
						beginPages: 1,
						endPages: 1,
						sidePages: 1
					})}
					onSelect={this.handleSelect}
				>
					<Paginator.Button
						page={page - 1}
						className={classNames("page--prev", { "page--disabled": page === 1 })}
					>
						<ArrowRight size="14" className="page-caret--prev" />
					</Paginator.Button>

					<Paginator.Segment field="beginPages" />
					<Paginator.Ellipsis previousField="beginPages" nextField="previousPages" />
					<Paginator.Segment field="previousPages" />
					<Paginator.Segment
						field="centerPage"
						className="active"
					/>
					<Paginator.Segment field="nextPages" />
					<Paginator.Ellipsis previousField="nextPages" nextField="endPages" />
					<Paginator.Segment field="endPages" />
					
					<Paginator.Button
						page={page + 1}
						className={classNames("page--next", { "page--disabled": page === pages})}
					>
						<ArrowRight size="14" className="page-caret--next" />
					</Paginator.Button>
				</Paginator.Context>				
			</div>
		) : null;
	}
}
