import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';

const NotificationsContainer = ({ notifications }) => (
  <Notifications
    notifications={notifications}
    style={false}
  />
);

NotificationsContainer.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
  })).isRequired,
};

export default connect(
  state => ({ notifications: state.rootReducer.notifications }),
)(NotificationsContainer);
