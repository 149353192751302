import React, { Component } from 'react';
import { Popup } from '../../../../ui';
import MapView from '../Map/MapView';

class MapModal extends Component {
	render() {
		return (
			<Popup.Container className="map-modal" isOpen={this.props.isOpen} toggle={this.props.toggle}>
				<Popup.Header toggle={this.props.toggle}>Карта</Popup.Header>
				<Popup.Body>
					<MapView />
				</Popup.Body>
			</Popup.Container>
		);
	}
}

export default MapModal;
