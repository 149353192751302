import React, { Component } from 'react';
import Construction, { EmptyScheme } from '../../../Constructions';

class Constructions extends Component {

	renderConstruction(type, sections) {
		const leafOpenTypes = sections.filter((section, index) => index !== 0).map(section => section.leafOpenType);		

		let position, common;
		switch (type) {
		case 'WINDOW_1':
			common = true; break;
		case 'WINDOW_2':
			common = true; break;
		case 'WINDOW_3':
			common = true; break;
		case 'WINDOW_4':
			position = { up: [4], down: [1, 2, 3] }; break;
		case 'WINDOW_5':
			position = { up: [3], down: [1, 2] }; break;
		case 'WINDOW_6':
			position = { up: [2], down: [1] }; break;
		case 'WINDOW_7':
			position = { up: [4, 3], down: [1, 2] }; break;
		case 'DOOR_1':
			return <Construction.Doors.DOOR_1 key={type} leafOpenTypes={leafOpenTypes} />;
		case 'DOOR_2':
			return <Construction.Doors.DOOR_2 key={type} leafOpenTypes={leafOpenTypes} />;
		case 'DOOR_3':
			return <Construction.Doors.DOOR_3 key={type} leafOpenTypes={leafOpenTypes} />;
		case 'DOOR_4':
			return <Construction.Doors.DOOR_4 key={type} leafOpenTypes={leafOpenTypes} />;
		default:
			return <EmptyScheme />;

		};
		return  <Construction.Window key={type} leafOpenTypes={leafOpenTypes} common={common} position={position} />;
	};

	render() {
		const {constructions} = this.props;

		return (
			<div className='flex flex--ai_flex-start flex--jc_center configuration-container'>
				{constructions.map((construction) => 
					this.renderConstruction(construction.type, construction.sections))
				}
			</div>		
		);
	}
}

export default Constructions;