import React, { Component } from 'react';
import OrdersPage from './OrdersPage';
import ArchivedOrdersListContainer from '../OrdersList/ArchivedOrdersListContainer';
import { STATUSES } from '../../../../utils/constants';

class ArchivedOrdersPage extends Component {

	render() {
		return ( 
			<OrdersPage>
				<ArchivedOrdersListContainer status={STATUSES.ARCHIVED} />
			</OrdersPage>
		);
	}
}

export default ArchivedOrdersPage;