import PopupContainer from './PopupContainer';
import PopupBody from './PopupBody';
import PopupHeader from './PopupHeader';
import PopupFooter from './PopupFooter';

export default {
	Container: PopupContainer,
    Header: PopupHeader,
	Body: PopupBody,
	Footer: PopupFooter,
};
