import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {Nav, NavItem} from 'reactstrap';

export default class NavBar extends Component {

	render() {

		return (
			<Nav vertical>
				<NavItem className="common-nav-item">
					<NavLink to="/orders">Заявки</NavLink>
				</NavItem>
				<NavItem className="sub-nav-item">
					<NavLink exact to="/archive">Архив</NavLink>
				</NavItem>
				<NavItem className="sub-nav-item">
					<NavLink exact to="/orders/map">Карта заявок</NavLink>
				</NavItem>
				<NavItem>
					<NavLink to="/3Dmodels">3D Модели</NavLink>
				</NavItem>
				<NavItem>
					<NavLink to="/clients">Клиенты</NavLink>
				</NavItem>
		  	</Nav>
		);
	}
}

