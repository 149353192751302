import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomField } from '../../../../ui';
import { getConfigurations } from '../../selectors';
import { getProfiles, getLaminations } from '../../../Terms/selectors';
import { windowsTerms } from '../../../../utils/constants';
import { getTerms, getSize, getCommonWidthHeight } from '../../../../utils/helpers';
import Constructions from './Constructions';

class DetailedOrderItemsList extends Component {

	renderSections(sections) {
		return(
			sections.map((section, index) =>
				<div key={section.id} className='ui-row flex flex--ai_baseline'>
					<div className='field-title ui-field-title'>
						{index === 0 ? 'Рама' : `Створка ${index}`}
					</div>
					<div className='field-value ui-field-value'>
						{index === 0 ?
							null
							:
							<div>
								{windowsTerms.leafOpenType[section.leafOpenType]}
							</div>
						}
						<div>
							{getSize(section.width, section.height)}
						</div>
					</div>
				</div>
			)
		);
	};

	renderConstructionParams(constructions) {
		const {laminations} = this.props;
		const getConstructionName = (construction) => windowsTerms.construction[construction.type];

		return(
			constructions.map((construction) =>
				<div key={construction.type} className='position-item--secondary'>
					<div className='windows-params-list__name'>{getConstructionName(construction)}</div>
					<div className='flex flex--wrap_wrap'>

						<CustomField title='Ламинация' value={getTerms(laminations, construction.lamination)}/>
						{construction.windowBoard ? 
							<CustomField
								title='Подоконник'
								value={getSize(construction.windowBoard)}
							/>
							: 
							null
						}
						{construction.windowSill ? 
							<CustomField
								title='Отлив'
								value={getSize(construction.windowSill)}
							/>
							: 
							null
						}
						{this.renderSections(construction.sections)}
					</div>
				</div>
			)
		);
	};

	render() {
		const {configurations, profiles} = this.props;
		return (
			<div className='detailed-wrapper windows-info-wrapper'>
				<h5 className='detailed-headline'>Список позиций</h5>
				{Object.values(configurations).map((configuration, index) =>
					<div key={configuration.name} className='position-item-container'>
						<div className='flex flex--ai_flex-start position-item--primary'>								
							<div className='position-item__number flex flex--jc_center flex--ai_center'>
								{index + 1}
							</div>
							<Constructions  constructions={configuration.constructions} />
							<div className='windows-params-list'>
								<CustomField
									className='windows-params-list__name'
									title='Название'
									value={configuration.name}
								/>
								<CustomField
									title='Размер (Ш х В)'
									value={getCommonWidthHeight(configuration.constructions)}
								/>
								<CustomField
									title='Профиль'
									value={getTerms(profiles, configuration.profile)}
								/>
							</div>
						</div>	
						{this.renderConstructionParams(configuration.constructions)}	
					</div>					
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	configurations: getConfigurations(state),
	profiles: getProfiles(state),
	laminations: getLaminations(state),
});

export default connect(mapStateToProps)(DetailedOrderItemsList);
