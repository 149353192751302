import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';

export default class CommonButton extends Component {

	static propTypes = {
		type: PropTypes.oneOf(['button', 'submit']),
		kind: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary']),
		staticKind: PropTypes.bool,
		size: PropTypes.oneOf(['xs', 'sm', 'md', 'sm-lg', 'l', 'lg']),
		appearance: PropTypes.oneOf(['link', 'danger', 'success']),
		opacity:  PropTypes.number,
		children: PropTypes.node.isRequired,
		hasError: PropTypes.bool,
		isActive: PropTypes.bool,
        isDisabled: PropTypes.bool,
        className: PropTypes.string,
		onBlur: PropTypes.func,
		onClick: PropTypes.func,
		onFocus: PropTypes.func,
		onMouseEnterHandler: PropTypes.func,
		onMouseLeaveHandler: PropTypes.func,
	};

	static defaultProps = {
		type: 'button',
        kind: 'default',
		size: 'md',
		opacity: 10,
		outline: false,
		onClick: null,
		iconLeft: null,
		iconRight: null,
		className: null,
		onMouseEnterHandler: null,
		onMouseLeaveHandler: null
	}

	handleClick = event => this.props.onClick(event, this.props.value);

	onMouseEnterHandler = event => this.props.onMouseEnter(event);

	onMouseLeaveHandler = event => this.props.onMouseLeave(event);

	render() {
		const {
			type,
			kind,
			size,
			opacity,
			outline,
			children,
			className,
			isDisabled,
			onClick,
			iconLeft,
			iconRight,
			style,
			onMouseEnter,
			onMouseLeave
		} = this.props;
		
		const buttonClasses = classNames(className, {
			[`btn-tertiary--opacity${opacity}`]: opacity && kind === 'tertiary'
		});


		return (
			<Button
				type={type}
                color={kind}
				size={size}
				className={buttonClasses}
				outline={outline}
				onClick={onClick ? this.handleClick : null}
				disabled={isDisabled}
				style={style}
				onMouseEnter={onMouseEnter ? this.onMouseEnterHandler : null}
				onMouseLeave={onMouseLeave ? this.onMouseLeaveHandler: null}
			>
				{iconLeft ? <span className="icon-holder icon-holder--left">{iconLeft}</span> : null}
				{children}
				{iconRight ? <span className="icon-holder icon-holder--right">{iconRight}</span> : null}
			</Button>
		);
	}
}