import React from 'react';
import Icon from 'react-icon-base';

const ArrowRight = props => (
	<Icon viewBox="0 0 16 16" {...props}>
		<path stroke="#000"
			strokeWidth={props.strokeWidth} d="M4 12.59L5.41 14l6-6-6-6L4 3.41 8.58 8z" />
	</Icon>
);

ArrowRight.defaultProps = {
	size: 12,
	strokeWidth: "0.1"
};

export default ArrowRight;