import { createAction } from 'redux-actions';
import { apiRequest, apiEndpoint, needRefreshToken, fetchRefreshToken } from '../../api';
import * as actions from './actionTypes';
import { notifyError } from '../Notifications';

export const startProfiles = createAction (actions.PROFILES_START);
export const stopProfiles = createAction (actions.PROFILES_STOP);

export const setProfilesSuccess = createAction(
	actions.PROFILES_SUCCESS,
	profilesData => ({profilesData})
);

export const fetchProfilesList = () => (dispatch) => {
	dispatch(startProfiles());

	return apiRequest(dispatch, {
		url: '/admin/profiles',
	}).then(responseData => {
		if (responseData.result === 'SUCCESS') {
			dispatch(setProfilesSuccess(responseData));
			dispatch(stopProfiles());
		} else if (responseData.result && responseData.result !== 'SUCCESS') {
			dispatch(notifyError(responseData.message || 'Невозможно загрузить список профилей'));
		} else {
			dispatch(notifyError('Ошибка сервера'));
		}
	});
};

export const startLaminations = createAction (actions.LAMINATIONS_START);
export const stopLaminations = createAction (actions.LAMINATIONS_STOP);

export const setLaminationsSuccess = createAction(
	actions.LAMINATIONS_SUCCESS,
	laminationsData => ({laminationsData})
);

export const fetchLaminationsList = () => (dispatch) => {
	dispatch(startLaminations());

	return apiRequest(dispatch, {
		url: '/admin/lamination',
	}).then(responseData => {
		if (responseData.result === 'SUCCESS') {
			dispatch(setLaminationsSuccess(responseData));
			dispatch(stopLaminations());
		} else if (responseData.result && responseData.result !== 'SUCCESS') {
			dispatch(notifyError(responseData.message || 'Невозможно загрузить список профилей'));
		} else {
			dispatch(notifyError('Ошибка сервера'));
		}
	});
};