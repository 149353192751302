import { handleActions } from 'redux-actions';
import  * as types from './actionTypes';

export const initialState = {
	isUserLoginFetching: false,
	userInfo: {},
	needShowValidationErrors: false,
};

export default handleActions({
	[types.HANDLE_SET_LOGIN_FORM]: (state, action) => ({
		...state,
		...action.payload.loginParametrs,
	}),
	[types.START_USER_LOGIN]: (state) => ({
		...state,
		isUserLoginFetching: true,
	}),
	[types.STOP_USER_LOGIN]: (state) => ({
		...state,
		isUserLoginFetching: false,
	}),
	[types.SET_USER_INFO_LIST]: (state, action) => ({
		...state,
		userInfo: action.payload.userInfo,
	}),
	[types.NEED_SHOW_VALIDATION_ERRORS]: (state) =>({
		...state,
		needShowValidationErrors: true,
	}),	
}, initialState);
