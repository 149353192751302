import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { apiEndpoint } from '../../api';
import {areAllOrdersSelected, isAnyOrderSelected, isXLSXDocumentFetching, isCSVDocumentFetching} from '../../app/Orders/selectors';
import {selectAllOrders, unselectAllOrders, handleSearch, fetchDownloadXLSX, fetchDownloadCSV} from '../../app/Orders/actions';
import {Loader, Checkbox, Button, Dropdown, Input} from '../../ui';
import {Play, Cross, Search} from '../../ui/icons';
import {ACTION_GROUP, sortingOptions} from '../../utils/constants';
import XLSX from '../../assets/img/xlsx-icon.svg';
import CSV from '../../assets/img/csv-icon.svg';
import DatePicker from './DatePicker';

class Filter extends Component {

    state = {
        isActionDropdownOpen: false,
        isSortingDropdownOpen: false,
        sortingDefaultTitle: 'Фильтр',
        actionTitle: ACTION_GROUP,
        filterValue: '',
        isClearCrossVisible: false
    }

    selectAll = () => {
        const {areAllOrdersSelected, selectAllOrders, unselectAllOrders} = this.props;

        if (areAllOrdersSelected) {
            unselectAllOrders();
        } else {
            selectAllOrders();
        }
    };

    sortingToggle = () => this.setState({ isSortingDropdownOpen: !this.state.isSortingDropdownOpen })

    handleSelectAction = (option) => {
        if (this.state.actionTitle !== option.title) {
            this.setState({ actionTitle: option.title })
        }
    };

    optionToggle = () => this.setState({ isOptionDropdownOpen: !this.state.isOptionDropdownOpen })

    handleSelectSortingOption = (option) => {
        if (this.state.sortingDefaultTitle !== option.title) {
            this.setState({ sortingDefaultTitle: option.title });
            this.props.handleSearch({ sortDirection: option.type });
        }
    };

    handleFilterChange = (value) => {
        if (value !== "") {
            this.setState({ filterValue: value, isClearCrossVisible: true });
            this.props.handleSearch({
                filter: value,
            });
		} else {
            this.resetFilterValue();
        }
    }
    
    resetFilterValue = () => {
        if (this.state.filterValue !== "") {
            this.setState({ filterValue: "", isClearCrossVisible: false });
            this.props.handleSearch({
                filter: null,
            });
        }
	}

	render() {
		const {isClearCrossVisible, filterValue, isSortingDropdownOpen, sortingDefaultTitle} = this.state;
        const {areAllOrdersSelected, isAnyOrderSelected, isXLSXDocumentFetching, isCSVDocumentFetching, fetchDownloadXLSX, fetchDownloadCSV } = this.props;
        // var token = localStorage.getItem('accessToken');
        // const linkXLSX = `${apiEndpoint}/admin/orders/export?format=XLSX&jwt=${token}`;
        // const linkCSV = `${apiEndpoint}/admin/orders/export?format=CSV&jwt=${token}`;

        return (
            <div className="filter-container">
                <div className="filter">
                    {/* <Checkbox
                        allSelected={areAllOrdersSelected}
                        anySelected={isAnyOrderSelected && !areAllOrdersSelected}
                        onChange={this.selectAll}
                    /> */}
                    {/* <div className="filter-dropdown-actions">
                        <Dropdown
                            toggle={this.actionToggle}
                            isOpen={isActionDropdownOpen}
                            list={actionsWithGroup}
                            value={actionTitle}
                            onSelect={this.handleSelectAction}
                            disabled={!isAnyOrderSelected}
                        />
                    </div> */}
                    {/* <Button
                        kind="primary"
                        size="xs"
                        className="group-action-button"
                        active={isAnyOrderSelected}
                        isDisabled={!isAnyOrderSelected || actionTitle === ACTION_GROUP}
                        onClick={isAnyOrderSelected ? (() => this.onActionClick()) : null}
                    >
                        <Play className="group-action-button__icon" size="14" />
                    </Button> */}
                    <div className="filter-dropdown-options">
                        <Dropdown
                            toggle={this.sortingToggle}
                            isOpen={isSortingDropdownOpen}
                            list={sortingOptions}
                            value={sortingDefaultTitle}
                            onSelect={this.handleSelectSortingOption}
                        />
                    </div>
                    <div className='input-search-container'>
                    <Input
                        className="input-search"
                        size="sm"
                        value={filterValue}
                        onChange={this.handleFilterChange}
                        iconLeft={<Search />}
                        iconRight={isClearCrossVisible ? <Cross stroke="#000000" onClick={this.resetFilterValue} className="input-search__cross" size="10" /> : null}
                    />
                    </div>
                    <DatePicker
                       handleChange={this.handleDateChange}  
                    />
                    <div className="flex flex--ai_center download-icons-group">
                        <div className='download-icons' onClick={() => fetchDownloadXLSX()}>
                            <img className='download-img' src={XLSX} alt='XLSX'/>
                            {isXLSXDocumentFetching ? <Loader /> : null}
                        </div>
                        <div className='download-icons' onClick={() => fetchDownloadCSV()}>
                            <img className='download-img' src={CSV} alt='CSV'/>
                            {isCSVDocumentFetching ? <Loader /> : null}
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

const mapStateToProps = (state) => ({
	areAllOrdersSelected: areAllOrdersSelected(state),
    isAnyOrderSelected: isAnyOrderSelected(state),
    isXLSXDocumentFetching: isXLSXDocumentFetching(state),
    isCSVDocumentFetching: isCSVDocumentFetching(state),

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	selectAllOrders,
    unselectAllOrders,
    handleSearch,
    fetchDownloadXLSX,
    fetchDownloadCSV
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
