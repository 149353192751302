import React, { Component } from 'react';
import { ModalHeader, Col } from 'reactstrap';
import Cross from '../icons/Cross';

export default class PopupHeader extends Component {
	
	handleToggle = () => this.props.toggle();

	render() {
		const {toggle, children} = this.props;

		return (
            <ModalHeader>
				<div className='modal-close' onClick={toggle ? this.handleToggle : null}>
					<Cross stroke="#0072bc" strokeWidth="1.5" className='modal-close__icon' size={18} />
				</div>
				<Col sm={12}>
					{children}
				</Col>
            </ModalHeader>
		);
	}
}