import React from 'react';

const CustomField = ({title, value, className}) => (
	<div className="ui-row flex flex--ai_baseline">
		<div className='field-title ui-field-title'>
			{title}
		</div>
		<div className={`field-value ui-field-value ${className || ''}`}>
			{value}
		</div>
	</div>
);

export default CustomField;
