import React, { Component } from 'react';
import {connect} from 'react-redux';
import scriptLoader from 'react-async-script-loader';
import * as R from 'ramda';
import { getCoordsList } from '../../selectors';
import { ROSTOV_COORD_CENTER } from '../../../../utils/constants';
import { Loader } from '../../../../ui';
import DetailedOrderModal from '../DetailedOrder/DetailedOrderModal';

class MapView extends Component {

    state = {
        map: {},
        visibleId: null,
        isModalOpen: false,
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isScriptLoaded && !this.props.isScriptLoaded) {
            if (nextProps.isScriptLoadSucceed) {
                this.geGoogletMap();
            }
        }
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;
        if (isScriptLoaded && isScriptLoadSucceed) {
          this.geGoogletMap();
        }
    }

    componentWillUnmount() {
        const { map } = this.state;
        if (!R.isEmpty(map)) {
            localStorage.setItem('clientCenterCoord',
                JSON.stringify({
                    lat:  map.getCenter().lat(),
                    lng:  map.getCenter().lng()
                })
            );
        }
    }

    getMapCenterCoord() {
        const clientCenterCoord = JSON.parse( localStorage.getItem('clientCenterCoord') );
        if (clientCenterCoord) {
            return clientCenterCoord;
        }
        return ROSTOV_COORD_CENTER;
    }

    geGoogletMap() {
        const { ordersWithCoord } = this.props;
        const google = window.google;

        const mapOptions = {
            zoom: 15,
            center: this.getMapCenterCoord(),
            gestureHandling: 'greedy'
        };
        const initMap =  new google.maps.Map(document.getElementById('map'), mapOptions);
        this.setState({map: initMap});
        let infoWindow = new google.maps.InfoWindow();;

        for (let i = 0; i < ordersWithCoord.length; i++) {
            let visibleId = ordersWithCoord[i]['visibleId'];
            let orderCoords = {
                lat: ordersWithCoord[i]["lat"],
                lng: ordersWithCoord[i]["lng"]
            };

            let marker = new google.maps.Marker({
                map: initMap,
                position: orderCoords,
                address: ordersWithCoord[i]["address"],
                name: ordersWithCoord[i]["name"],
                email: ordersWithCoord[i]["email"],
                phone: ordersWithCoord[i]["phone"],
            });
            google.maps.event.addListener(marker, 'mouseover', function () {
                infoWindow.setContent(
                    `<div class='tooltip-container'>
                        <div>${this.address}</div>
                        <div>${this.name}</div>
                        <div>${this.phone}</div>
                        <div>${this.email}</div>
                    </div>`
                );
                infoWindow.open(initMap, this);
            });
            google.maps.event.addListener(marker, 'click', () =>  {
                this.setState({visibleId: visibleId});
                this.toggleModal();
            });
        }
    }

    toggleModal = () => this.setState({ isModalOpen: !this.state.isModalOpen });

    render() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;

        return(
            isScriptLoaded && !isScriptLoadSucceed ?
                <Loader />
            :
                <div>
                    <div id="map" style={{ height: '60vh', width: '100%', position: 'relative' }} />
                    <DetailedOrderModal visibleOrderIdFromMap={this.state.visibleId} isOpen={this.state.isModalOpen} toggle={this.toggleModal} />
                </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ordersWithCoord: getCoordsList(state)
});

const callbackName = "initMap";
const URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_MAP}&${callbackName}`;

export default connect(mapStateToProps)(scriptLoader(URL)(MapView));