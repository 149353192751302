import React, {Component} from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { Button } from '../../ui';
import logoRight from '../../assets/img/logoright@3x.png';

class Header extends Component {

	handleLogOut = () => {
		localStorage.removeItem('expiresAfter');
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('clientCenterCoord');
		this.props.history.push('/login');
	}

	render() {

		return (
			<div className="header-container">
				<div className="header flex flex--jc_space-between">
					<div className="logo-wrap">
						<Link to='/'>
							<img src={logoRight} alt="Конфигуратор VEKA" />
						</Link>
                    </div>
					<Col sm={2}>
						<Button size="sm-lg" kind="tertiary" opacity={20} style={{"width" : "100%"}} onClick={this.handleLogOut}>
							Выйти
						</Button>
                    </Col>
				</div>
			</div>
		);
	}
}

export default withRouter(Header);
