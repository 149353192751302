import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Checkbox extends Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
        anySelected: PropTypes.bool,
        allSelected: PropTypes.bool,
    }

    static defaultProps = {
        type: 'checkbox',
        onChange: null,
        className: '',
        anySelected: false,
        allSelected: false,
    }

    handleChange = () => this.props.onChange();

	render() {
        const {type, allSelected, anySelected} = this.props;
        const checkboxClasses = classNames('checkbox-input', {
            'checkbox-input--checked': allSelected,
        });
		const checkboxSpanClasses = classNames('checkbox-span', {
            'checkbox-span--checked': allSelected,
            'checkbox-span--checked checkbox-span--checked-any': anySelected && !allSelected,
        })

        return (
                <label className="checkbox-label">
                    <input
                        type={type}
                        onChange={this.handleChange}
                        className={checkboxClasses}
                    />
                    <span
                        className={checkboxSpanClasses}
                    ></span>
                </label>
        );
	}
}