import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Popup } from '../../../../ui';
import DetailedOrder from './';
import { fetchDetailedOrder } from '../../actions';
import { isDetailedOrderFetching } from '../../selectors';
import { withRouter } from 'react-router-dom';

class DetailedOrderModal extends Component {

	render() {
		const {
			isOpen,
			toggle,
			visibleOrderIdFromMap,
			match: {
				params: {
					visibleOrderId
				}
			}
		} = this.props;

		return (
			<Popup.Container isOpen={isOpen} toggle={toggle} className="modal-order">
				<Popup.Header toggle={toggle}>Данные заявки</Popup.Header>
				<DetailedOrder  visibleOrderId={visibleOrderId || visibleOrderIdFromMap} />
			</Popup.Container>
		);
	}
}

const mapStateToProps = (state) => ({
	isFetching: isDetailedOrderFetching(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	fetchDetailedOrder,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailedOrderModal));
