import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Loader = ({size, white}) => (
 	<div className={`loader-container loader-container-${size}`}>
		<div className={classNames(`loader loader-${size}`, {
        		'loader--white': white,
            })}
		/>
	</div> 
);

Loader.defaultProps = {
	size: 'sm',
	white: false
};

Loader.propTypes = {
	type: PropTypes.oneOf(['sm', 'md']),
};



export default Loader;
