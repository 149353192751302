import React, { Component } from 'react';
import OrdersPage from './OrdersPage';
import NewOrdersListContainer from '../OrdersList/NewOrdersListContainer';

class NewOrdersComponen extends Component {

	render() {
		return ( 
			<OrdersPage>
				<NewOrdersListContainer />
			</OrdersPage>
		);
	}
}

export default NewOrdersComponen;