import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import classNames from 'classnames';

export default class CommonInput extends Component {

	static propTypes = {
		type:  PropTypes.string,
		placeholder: PropTypes.string,
		value: PropTypes.string,
		size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
        className: PropTypes.string,
		onFocus: PropTypes.func,
		hasError: PropTypes.bool,
		autocomplete: PropTypes.string,
	};

	static defaultProps = {
		type: 'text',
		value: '',
		size: "md",
		placeholder: '',
		autoComplete: 'off',
	}

    handleChange = (event) => {
		const {onChange} = this.props;
        const {value} = event.currentTarget;
		onChange(value, event);
	}
	
	handleFocus = () => this.props.onFocus();

	render() {
		const {type, value, name, size, autocomplete, placeholder, className, onChange, onFocus, needShowErrors, errorMessage, iconLeft, iconRight} = this.props;
		const inputClasses = classNames(className, {
            'form-control--error': needShowErrors,
		});
		
        return (
			<div
              className={classNames('input-container', className, {
                'input-container--icon-left': iconLeft,
				'input-container--icon-right': iconRight,
			})}
			>
				<Input
					type={type}
					name={name}
					value={value}
					bsSize={size}
					placeholder={placeholder}
					autoComplete={autocomplete}
					className={inputClasses}
					onChange={onChange ? this.handleChange : null}
					onFocus={onFocus  ? this.handleFocus : null}
				/>
				{needShowErrors ? <span className="error-message">{errorMessage}</span> : null}
				{iconLeft ?  <span className="icon-wrap icon-wrap--left">{iconLeft}</span> : null}
                {iconRight ? <span className="icon-wrap icon-wrap--right">{iconRight}</span> : null}         
			</div>		
		);
	}
}
