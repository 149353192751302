import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from '../../../../ui';
import { getDetailedOrder, getOrderLatitude, getOrderLongitude, getConfigurations, getConfigurationsLength } from '../../selectors';

class PersonalInfo extends Component {

	render(){
		const {order, latitude, longitude, configurationsLength} = this.props;
		return (
			<div className="detailed-wrapper person-info-wrapper">
				<h5 className="detailed-headline">Личные данные клиента</h5>
				<div className="person-info-container">
					<Field
						title="Адрес замера"
						value={order.address}
					/>
					<Field
						title="Координаты"
						value={`${latitude}, ${longitude}`}
					/>
					<Field
						title="Имя"
						value={order.name}
					/>
					<Field
						title="Телефон"
						value={order.phone}
					/>
					<Field
						title="E-mail"
						value={<a className="link" href={`mailto:${order.email}?subject=Конфигуратор Veka`}>{order.email}</a>}
					/>
					{order.comment ? 
						<Field
							title="Примечания"
							value={order.comment}
							valueSm={8}
						/>
						:
						null
					}
				</div>
				<Field
					title="Конфигураций для расчета"
					value={configurationsLength}
				/>
			</div>
		);
	};
}

const mapStateToProps = (state) => ({
	order: getDetailedOrder(state),
	latitude: getOrderLatitude(state),
	longitude: getOrderLongitude(state),
	configurations: getConfigurations(state),	
	configurationsLength: getConfigurationsLength(state),	
});

export default connect(mapStateToProps)(PersonalInfo);