import { combineReducers } from 'redux';
import orders from './app/Orders/reducer';
import terms from './app/Terms/reducer';
import userLogin from './app/Login/reducer';
import { reducer as notifications } from 'react-notification-system-redux';

const rootReducer = combineReducers({
	orders,
	terms,
	userLogin,
	notifications,
});

export default rootReducer;
 