import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class AccessRoute extends Component {

	hasAccess = () => {
		const session = {
			accessToken: localStorage.getItem('accessToken'),
			refreshToken: localStorage.getItem('refreshToken'),
		};
		return Boolean(session.accessToken || session.refreshToken);
	}

  	render() {
    	return this.hasAccess() ?
			<Route {...this.props} />
		:
			<Redirect to={{ pathname: "/login", state: { from: this.props.location } }}
			/>;
	};
}
