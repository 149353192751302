import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import ArrowRight from '../icons/ArrowRight';

export default class Dropdown extends Component {

    static propTypes = {
		list: PropTypes.array.isRequired,
		size: PropTypes.oneOf(['sm', 'md']),
		value: PropTypes.string.isRequired,
		onSelect: PropTypes.func.isRequired,
		className: PropTypes.string,
		disabled: PropTypes.bool,
    }
    
    static defaultProps = {
		disabled: false,
		size: 'sm',
		className: '',
	};
	
	toggle = () => this.props.toggle();
    
    handleOptionClick = (event, option) => {
		event.stopPropagation();
		event.preventDefault();
		this.props.onSelect(option);
	};

	render() {
		const {size, isOpen, value, list} = this.props;

		return (
			<ButtonDropdown color="default" className='ui-dropdown ui-dropdown--blue' isOpen={isOpen} toggle={this.toggle}>
				<DropdownToggle color='default' className={`dropdown-toggle dropdown-toggle--${size}`}>
					{value}
				</DropdownToggle>
				<DropdownMenu>	
					{list.map((listItem, index) => 
					  <DropdownItem
							key={listItem.title} 
							active={listItem.title === value}
							onClick={(event) => this.handleOptionClick(event, listItem)}
							className="ac-dropdown__item"
						>
							{listItem.title}
						</DropdownItem> 
					)}
				</DropdownMenu>
				<ArrowRight size={14} className="ui-dropdown__caret" />
		  	</ButtonDropdown>
		);
	}
}
