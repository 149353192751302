import React, { Component } from 'react';
import { ModalFooter } from 'reactstrap';

export default class PopupFooter extends Component {

	render() {
		const {children} = this.props;
		return (
        	<ModalFooter>{children}</ModalFooter>
		);
	}
}