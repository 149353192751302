import React, { Component } from 'react';
import Filter from '../../../Filter';

class OrdersPage extends Component {

	render() {
		return ( 
			<>
				<Filter />
				{this.props.children}
			</>
		);
	}
}

export default OrdersPage;