import React from 'react';
import * as Doors from './Doors';
import Window from './WindowsV2';

export function getOpenTypeClass(id, leafOpenTypes) {
	const leafOpenType = leafOpenTypes.filter((type, index) => index + 1 ===  id)[0];
	let cssClass;
	if(typeof(leafOpenType) !== 'undefined') {
		if (leafOpenType === null) {
			cssClass = 'section--empty';
		} else if (leafOpenType.length > 0) {
			cssClass = `section--${leafOpenType.toLowerCase()}`;
		}
	} else {
		cssClass = '';
	}
    
	return cssClass;
};

export const EmptyScheme = () => <div>Схема временно отсутствует</div>;

export default {
	Doors: Doors,
	Window: Window,
};

