import {handleActions} from 'redux-actions';
import  * as types from './actionTypes';

export const initialState = {
	isProfilesFetching: false,
	profiles: [],
	isLaminationsFetching: false,
	laminations: [],
};

export default handleActions({
	[types.PROFILES_START]: (state) => ({
		...state,
		isProfilesFetching: true,
	}),
	[types.PROFILES_STOP]: (state) => ({
		...state,
		isProfilesFetching: false,
	}),
	[types.PROFILES_SUCCESS]: (state, action) => ({
		...state,
		profiles: action.payload.profilesData.list,
	}),
	[types.LAMINATIONS_START]: (state) => ({
		...state,
		isLaminationsFetching: true,
	}),
	[types.LAMINATIONS_STOP]: (state) => ({
		...state,
		isLaminationsFetching: false,
	}),
	[types.LAMINATIONS_SUCCESS]: (state, action) => ({
		...state,
		laminations: action.payload.laminationsData.list,
	}),
}, initialState);
