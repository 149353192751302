export const getLoginForm = state => state.rootReducer.userLogin;

export const isFormValid = state => {
    return Boolean(
        state.rootReducer.userLogin.email &&
        state.rootReducer.userLogin.password
    );
};

export const needShowValidationErrors = state => {
    return state.rootReducer.userLogin.needShowValidationErrors
};

export const isUserLoginFetching = state => state.rootReducer.userLogin.isUserLoginFetching

export const getAuthUser = state => state.rootReducer.userLogin.userInfo;        