import { createAction } from 'redux-actions';
import * as actions from './actionTypes';
import { apiEndpoint } from '../../api';
import { notifyError } from '../Notifications';

export const startUserLogin = createAction (
	actions.START_USER_LOGIN
);

export const stopUserLogin = createAction (
	actions.STOP_USER_LOGIN
);

export const setUserInfo = createAction(
	actions.SET_USER_INFO_LIST,
	userInfo => ({userInfo}),
);

export const fetchUserLogin = (email, password) => (dispatch) => {

	dispatch(startUserLogin());
    
	return fetch(`${apiEndpoint}/auth/login`, {
		headers: {
			'Content-type': 'application/json',
		},
		method: 'post',
		credentials: 'include',
		body: JSON.stringify({email, password}),
	})
		.then(response => {
			if (response.status >= 400) {
				dispatch(notifyError('Ошибка сервера'));
				return response;
			}

			let contentType = response.headers.get('content-type');
			if (contentType && contentType.includes('application/json')) {
				return response.json();
			} else {
				return response;
			}
		})
		.then(dataJSON => {
			if (dataJSON.result === 'SUCCESS') {
				localStorage.setItem('expiresAfter', dataJSON.expiresAfter);
				localStorage.setItem('accessToken', dataJSON.accessToken);
				localStorage.setItem('refreshToken', dataJSON.refreshToken);
				dispatch(setUserInfo(dataJSON));
				dispatch(stopUserLogin());
			} else if (dataJSON.result && dataJSON.result !== 'SUCCESS') {
				dispatch(stopUserLogin());
				dispatch(notifyError(dataJSON.message || ''));
			} else {
				dispatch(notifyError('Ошибка сервера'));
			}
		});
};

export const handleSetLoginForm = createAction (
	actions.HANDLE_SET_LOGIN_FORM,
	(loginParametrs) => ({loginParametrs})
);

export const showValidationErrors = createAction (
	actions.NEED_SHOW_VALIDATION_ERRORS,
);
