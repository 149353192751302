import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Loader } from '../../../../ui';
import { fetchChangeStatus } from "../../detailedOrderAction";
import { isNewStatusFetching, getDetailedOrder } from '../../selectors';
import { apiEndpoint } from '../../../../api';
import { STATUSES } from '../../../../utils/constants';

class DetailedOrderButtonGroup extends Component {

    static propTypes = {
        order: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
          })).isRequired,
        fetchChangeStatus: PropTypes.func.isRequired,
        isNewStatusFetching: PropTypes.bool.isRequired
    }

    handleSave = () => {
        const link = `${apiEndpoint}/admin/orders/${this.props.order.id}/pdf?jwt=${localStorage.getItem('accessToken')}`;
        window.open(link, '_self');
    }

    getStatusTitle = () => {
        let statusTitle;
        if (this.props.order.status === STATUSES.ARCHIVED) {
            statusTitle = 'Восстановить';
        } else {
            statusTitle = 'В архив';
        }
        return statusTitle; 
    }

    handleChangeStatus = () => {
        let status;
        if (this.props.order.status === STATUSES.ARCHIVED) {
            status = STATUSES.CREATED;
        } else {
            status = STATUSES.ARCHIVED;
        }
        this.props.fetchChangeStatus(status, this.props.order.id);
    }


	render() {
        const { isNewStatusFetching } = this.props;
        
		return (
            <div className="flex flex--jc_flex-end button-group">
                <div className="flex flex--jc_space-between button-sub-group">
                    <Button size="sm-lg" kind="tertiary" opacity={20} onClick={this.handleChangeStatus}>
                        {isNewStatusFetching ? <Loader /> : this.getStatusTitle()}
                    </Button>
                    <Button kind="primary" size="sm-lg" onClick={this.handleSave}>
                        Сохранить PDF
                    </Button>
                </div>
            </div>
        );
	}
}

const mapStateToProps = (state) => ({
    isNewStatusFetching: isNewStatusFetching(state),
    order: getDetailedOrder(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchChangeStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailedOrderButtonGroup);
