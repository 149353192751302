import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { Popup, Loader } from '../../../../ui';
import { fetchDetailedOrder } from '../../actions';
import { isDetailedOrderFetching, getConfigurations } from '../../selectors';
import PersonalInfo from './PersonalInfo';
import DetailedOrderItemsList from './DetailedOrderItemsList';
import DetailedOrderButtonGroup from './DetailedOrderButtonGroup';

class DetailedOrder extends Component {

	componentDidMount() {
		this.props.fetchDetailedOrder(this.props.visibleOrderId);
	}

	render() {
		const {isFetching, configurations} = this.props;
		
		return (
			isFetching ? 
				<Loader size="md"/>
				:
				<>
					<Popup.Body>
						<Container>
							<PersonalInfo />
							{configurations ? <DetailedOrderItemsList /> : null}
						</Container>
					</Popup.Body>
					<Popup.Footer>
						<DetailedOrderButtonGroup />
					</Popup.Footer>
				</>
		);
	}
}

const mapStateToProps = (state) => ({
	isFetching :isDetailedOrderFetching(state),
	configurations: getConfigurations(state),	
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	fetchDetailedOrder,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailedOrder);
