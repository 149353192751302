import { createAction } from 'redux-actions';
import * as actions from './actionTypes';
import { apiRequest } from '../../api';
import { notifyError } from '../Notifications';

export const setNewStatus = createAction(
	actions.SET,
	newStatus => ({newStatus})
);

export const startChangeStatus = createAction(actions.ORDER_START_CHANGE_STATUS);
export const stopChangeStatus = createAction(actions.ORDER_STOP_CHANGE_STATUS);

export const fetchChangeStatus = (status, id) => (dispatch) => {
	dispatch(startChangeStatus());

	return apiRequest(dispatch, {
		method: 'POST',
		url: '/admin/orders/changeStatus',
		contentType: 'application/json',
		body: {
			idList: [id],
			status: status
		}
	}).then(responseData => {
		if (responseData.result === 'SUCCESS') {
			dispatch(setNewStatus(status));
			dispatch(stopChangeStatus());
		}
		else if (responseData.result && responseData.result !== 'SUCCESS') {
			dispatch(notifyError(responseData.message || 'Неизвестная ошибка'));
			dispatch(stopChangeStatus());
		}
		else {
			dispatch(notifyError());
			dispatch(stopChangeStatus());
		}
	});
};