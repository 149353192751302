import React, {Component} from 'react';
import dirSVG from '../../assets/img/dir.svg';

export default class InDeveloping extends Component {

	render() {
		return (
			<div className="empty-list appearance">
				<img className="empty-list__img" src={dirSVG} alt="Здесь пока ничего нет" />
				<div>Здесь пока ничего нет.</div>
			</div>
		);
	}
}