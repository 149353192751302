import { notifyError } from '../app/Notifications';
const queryString = require('query-string');

export const apiEndpoint = process.env.REACT_APP_VEKA_API_ENDPOINT;
export const getAuthorizationValue = () => {
	return `Bearer ${localStorage.getItem('accessToken')}`;
};

export const apiRequest = async(dispatch, options) => {
	
	if (needRefreshToken()) {
		await fetchRefreshToken();
	}

	const defaultOptions = () => ({
		authorization: getAuthorizationValue(),
		accept: '*/*',
		contentType: 'plain/text',
		method: 'get',
		type: 'json',
		url: '',
		queryParameters: null,
		body: null,
	});

	const {
		authorization,
		accept,
		contentType,
		method,
		url,
		queryParameters,
		type,
		body
	} = { ...defaultOptions(),
		...options
	};

	let requestURL;
	if (queryParameters) {
		requestURL = `${apiEndpoint}${url}?${queryString.stringify(queryParameters)}`;
	}
	else {
		requestURL = `${apiEndpoint}${url}`;
	}

	const getQueryBody = () => {
		if (body) {
			return JSON.stringify(body);
		} else {
			return null;
		}
	};

	return fetch(requestURL, {
		method: method,
		headers: {
			'Authorization': authorization,
			'Accept': accept,
			'Content-Type' : contentType
		},
		body: getQueryBody()
	}).then(response => {
		if (response.status >= 400) {
			dispatch(notifyError('Ошибка сервера'));
			return response;
		}
		// response.headers.get('Content-Type') // has() => Boolean

		switch (type) {
		case 'json':
			return response.json();
		case 'blob':
			return response.clone().blob();
		case 'text':
			return response.text();
		default:
			return response.json();
		}
	}).then(responseData => responseData);
};

export const needRefreshToken = () => {
	const session = {
		expiresAfter: localStorage.getItem('expiresAfter')
	};
	const date = new Date();
	const actualDateISO = date.toISOString();
	return actualDateISO > session.expiresAfter;
};

export const fetchRefreshToken = () => {

	return fetch(`${apiEndpoint}/auth/refresh`, {
		headers: {
			'Content-type': 'application/json',
		},
		method: 'post',
		credentials: 'include',
		body: JSON.stringify({refreshToken: localStorage.getItem('refreshToken')}),
	})
		.then(response => {
			if (response.status >= 400) {
				return response;
			}

			let contentType = response.headers.get('content-type');
			if (contentType && contentType.includes('application/json')) {
				return response.json();
			} else {
				return response;
			}
		})
		.then(dataJSON => {
			if (dataJSON.result === 'SUCCESS') {
				localStorage.setItem('expiresAfter', dataJSON.expiresAfter);
				localStorage.setItem('accessToken', dataJSON.accessToken);
			}
		});
};