import React, { Component } from 'react';
import classNames from 'classnames';
import { getOpenTypeClass } from './';

const Impost = () => <div className='impost' />;

const DoorFrame = ({children, double}) => {
	return (
		<div className={classNames('frame-wrap door-frame-wrap', {
			'door-frame-wrap--double': double,
		})}>
			<div className='flex frame'>
				{children}
			</div>
		</div>	
	);
};

const DoorSectionGroup = ({column, children}) => {
	return (
		<div className={classNames('flex door-section-group', {
			'flex-col': column,
		})}>
			{children}
		</div>	
	);
};

const DoorSection = ({up, down, id, leafOpenTypes}) => {
	const cssClass = getOpenTypeClass(id, leafOpenTypes);

	return (
		<div className={classNames(`section ${cssClass}`, {
			'door-section--up': up,
			'door-section--down': down,
		})}        
		/>
	);
};

DoorSection.defaultProps = {
	leafOpenTypes: [],
};

export class DOOR_1 extends Component {
	render() {
		return (
			<DoorFrame>
				<DoorSectionGroup column>
					<DoorSection up id={1} leafOpenTypes={this.props.leafOpenTypes}/>
					<DoorSection down />
				</DoorSectionGroup>
			</DoorFrame>
		);
	}
};

export class DOOR_2 extends Component {
	render() {
		return (
			<DoorFrame double >
				<DoorSectionGroup column>
					<DoorSection up id={1} leafOpenTypes={this.props.leafOpenTypes} />
					<DoorSection down />
				</DoorSectionGroup>
				<Impost />
				<DoorSectionGroup column>
					<DoorSection up id={2} leafOpenTypes={this.props.leafOpenTypes} />
					<DoorSection down />
				</DoorSectionGroup>
			</DoorFrame>
		);
	}
};

export class DOOR_3 extends Component {
	render() {
		return (
			<DoorFrame>
				<DoorSectionGroup>
					<DoorSection id={1} leafOpenTypes={this.props.leafOpenTypes}/>
				</DoorSectionGroup>
			</DoorFrame>
		);
	}
};

export class DOOR_4 extends Component {
	render() {
		return (
			<DoorFrame double>
				<DoorSectionGroup>
					<DoorSection id={1} leafOpenTypes={this.props.leafOpenTypes} />
					<Impost />
					<DoorSection id={2} leafOpenTypes={this.props.leafOpenTypes} />
				</DoorSectionGroup>
			</DoorFrame>
		);
	}
};