import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectOrder, unselectOrder } from '../../actions';
import { isOrderSelected } from '../../selectors';
import { getProfiles } from '../../../Terms/selectors';
import classNames from 'classnames';
import { Checkbox, Button, ArrowRight } from '../../../../ui';
import { Row, Col, ListGroupItem } from 'reactstrap';
import { getTerms, getDateFormat } from '../../../../utils/helpers';

class OrdersListItem extends Component {

	state = {
		isHover: false,
	};

	onMouseEnterHandler = event => this.setState({isHover: true});

	onMouseLeaveHandler = event =>this.setState({isHover: false});

	onCheck = () => {
		const {selectOrder, order, isOrderSelected, unselectOrder} = this.props;

		if (!isOrderSelected) {
			selectOrder(order.id)
		} else {
			unselectOrder(order.id)
		}
	}

	handleOpenModalClick = () => {
		const { history, order } = this.props;
		history.push(`${history.location.pathname}/${order.visibleId}`);
	}

	render() {
		const {isHover} = this.state;
		const {profiles, order, isOrderSelected} = this.props;

		return (
			<ListGroupItem
				className={classNames('list-item', {
					'list-item--checked': isOrderSelected || isHover,
				})}
			>
				<Row className="flex--ai_center">
					<Col md={1}>
						<Checkbox allSelected={isOrderSelected} onChange={this.onCheck}/>
					</Col>
					<Col md={10} className="list-item__common">
						{order.address}
					</Col>

					<div className="sub-info-container">
						<div className="sub-info">
							<span className="sub-info__title">ID</span>
							{order.visibleId}
						</div>
						<div className="sub-info">
							<span className="sub-info__title">Объектов</span>
							{order.constructionAmount}
						</div>
						<div className="sub-info flex flex-col">
							<span className="sub-info__title">Профили:</span>
							{order.profiles.map(profile => <div key={profile}>{getTerms(profiles, profile)}</div>)}		
						</div>
					</div>
				</Row>
				<Row className="flex--ai_baseline">
					<Col md={{size: 'auto',offset: 1}}>
						<div className="list-item__title">Имя</div>
						<div>{order.name}</div>
					</Col>

					<div>
						<div className="list-item__title">Моб.</div>
						<div>{order.phone}</div>
					</div>

					<div>
						<div className="list-item__title">Email</div>
						<div className="list-item__email">
							<a className="link" href={`mailto:${order.email}?subject=Конфигуратор Veka`}>{order.email}</a>
						</div>
					</div>
				</Row>
				<Row className="flex--ai_center">
					<Col md={{offset: 1}}>
						<Col md={12} className="list-item__title list-item__title-date">Дата создания</Col>
						<Col md={12}>{getDateFormat(order.createdDateTime)}</Col>
					</Col>
					{order.region ?
						<Col md={4}>
							<div className="list-item__title">Регион</div>
							<div>{order.region}</div>
						</Col>
					:
						null
					}
					<Col md={2}>
						<Button
							kind="tertiary"
							size="l"
							onClick={this.handleOpenModalClick}
							iconRight={<ArrowRight/>}
							onMouseEnter={this.onMouseEnterHandler}
							onMouseLeave={this.onMouseLeaveHandler}
						>
							Подробно
						</Button>
					</Col>
				</Row>
			</ListGroupItem>
		);
	}
}

const mapStateToProps = (state, props) => ({
	isOrderSelected: isOrderSelected(state, props.order.id),
	profiles: getProfiles(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	selectOrder,
	unselectOrder,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersListItem));