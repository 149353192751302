import React from 'react';
import Icon from 'react-icon-base';

const ErrorIcon = props => (
	<Icon viewBox="0 0 48 48" {...props}>
		<g fill="none" fillRule="evenodd" stroke="#DC3545" strokeWidth={props.strokeWidth}>
			<path d="M0 0l48 48M0 48L48 0"/>
		</g>
	</Icon>
);

ErrorIcon.defaultProps = {
	size: 12,
	strokeWidth: 4
};

export default ErrorIcon;