import moment from 'moment';
import 'moment/locale/ru.js';
import { DATE_FORMAT } from '../utils/constants';

export const getDateFormat = (date, dateFormat) => {
	if (date) {
		const formattedDate = moment(date).locale('ru').format(dateFormat || DATE_FORMAT);
		if (formattedDate === 'Invalid date') {
			return 'Нет';
		}
		return formattedDate;
	} else {
		return '';
	}
};

/**
 *
 * @param  {String} size1
 * @param  {String} size2
 * @return {String}
 */
export const getSize = (size1, size2) => {
	size1 = parseInt(size1, 10);
	size2 = parseInt(size2, 10);
	if (size1 && size2) {
		return `${size1} X ${size2} мм`;
	} else if (size1 && !size2) {
		return `${size1} мм`;
	} else {
		return 'Нет';
	}
};

export const getSumm = (array) => array.reduce((accumulator, currentValue) => accumulator + currentValue);

export const getMaxValue = (array) => {
	let maxValue  = array[0];
	for (let i = 0; i < array.length; i++) {
		if (array[i] > maxValue) {
			maxValue = array[i];
		}
	};
	return maxValue;
};

export const getCommonWidthHeight = (constructions) => {
	const firstSectionsWidths = constructions.map((construction) => construction.sections[0].width);
	const firstSectionsHeight = constructions.map((construction) => construction.sections[0].height);
	return getSize(getSumm(firstSectionsWidths), getMaxValue(firstSectionsHeight));
}; 

export const getFilenameFromResponse = (response) => {
	let filename = '';
	const disposition = response.headers.get('Content-Disposition');
	if (disposition && disposition.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}
	return filename;
};

export const downloadFileFromResponse = (blob) => {
	// const filename = getFilenameFromResponse(blob); 
	let filename;
	const a = document.createElement('a'),
		downloadUrl = URL.createObjectURL(blob);
	a.href = downloadUrl;
	if (filename) {
		a.download = filename;
	}
	document.body.appendChild(a);
	a.click();
	setTimeout(() => {
		document.body.removeChild(a);
		window.URL.revokeObjectURL(downloadUrl);
	}, 100);
};

export const getTerms = (list, itemId) => (list.find(item => item.id === itemId)|| {}).title || itemId;