import React from 'react';
import Icon from 'react-icon-base';

const Cross = props => (
	<Icon viewBox="0 0 18 18" {...props}>
		<g fill="none" fillRule="evenodd" stroke={props.stroke} strokeWidth={props.strokeWidth}>
        	<path d="M1 1l16 16M1 17L17 1"/>
    	</g>
	</Icon>
);

Cross.defaultProps = {
	size: 12,
	stroke: 'rgba(0, 0, 0, .3)',
	strokeWidth: 2,
};

export default Cross;