import {ITEMS_PER_PAGE} from '../../utils/constants';

export const isOrdersListFetching = state => state.rootReducer.orders.isOrdersListFetching;
export const isXLSXDocumentFetching = state => state.rootReducer.orders.isXLSXDocumentFetching;
export const isCSVDocumentFetching = state => state.rootReducer.orders.isCSVDocumentFetching;
export const getOrdersList = state => state.rootReducer.orders.ordersList;
export const getOrdersListLength = state => state.rootReducer.orders.ordersList.length;
export const getSearchParameters = state => state.rootReducer.orders.searchParameters;
export const getCoordsList = state => {
	return state.rootReducer.orders.ordersList
		.filter(item => item.coord)
		.map(item => item)
		.map(({ id, visibleId, address, coord, name, email, phone }) => ({
			id: id,
			visibleId: visibleId,
			address: address,
			lat: coord.lat,
			lng: coord.lon,
			name: name,
			email: email,
			phone: phone
		}));
};

export const getCurrentPage = state => state.rootReducer.orders.searchParameters.currentPage;
export const getTotalItems = state => state.rootReducer.orders.totalItems;
export const getTotalPages = state => Math.ceil(getTotalItems(state) / ITEMS_PER_PAGE);
export const getPagingParameters = state => ({
	totalPages: getTotalPages(state),
	totalItems: getTotalItems(state),
	currentPage: getCurrentPage(state),
	elementsOnPage: getOrdersListLength(state),
});

export const isDetailedOrderFetching = state => state.rootReducer.orders.isDetailedOrderFetching;
export const getDetailedOrder = state => state.rootReducer.orders.detailedOrder;
export const getOrderLatitude = state => {
	if (state.rootReducer.orders.orderCoordinates) {
		return state.rootReducer.orders.orderCoordinates.lat;
	} else {
		return 0;
	}
};

export const getOrderLongitude = state => {
	if (state.rootReducer.orders.orderCoordinates) {
		return state.rootReducer.orders.orderCoordinates.lon;
	} else {
		return 0;
	}
};

export const isNewStatusFetching = state => state.rootReducer.orders.isNewStatusFetching;

export const getOrderItems = state => state.rootReducer.orders.orderItems;
export const getOrderItemsLength = state => state.rootReducer.orders.orderItems.length;
export const getConfigurations = state => state.rootReducer.orders.configurations;
export const getConfigurationsLength = state => state.rootReducer.orders.configurations.length;

export const getSelectedOrders = state => state.rootReducer.orders.selectedOrders;
export const isOrderSelected = (state, order) => getSelectedOrders(state).includes(order);
export const areAllOrdersSelected = (state) => getSelectedOrders(state).length === getOrdersListLength(state) && getOrdersListLength(state) > 0;
export const isAnyOrderSelected = (state) => getSelectedOrders(state).length > 0;